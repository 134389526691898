export default {
  nav: {
    login: "Войти как админ",
    logout: "Выйти",
    notion_link:
      "https://speechmate.yonote.ru/share/3fc951ae-b504-48c6-bd16-07729d4612e4",
    monitoring: "Мониторинг",
    hubs: "Хабы",
    team: "Сотрудники",
    accounts: "Аккаунты",
    settings: "Настройки",
    monitoring_owner: "Мониторинг",
    customers: "Клиенты",
    support: "Поддержка",
    applications: "Приложения",
  },
  general: {
    placeholder: "Выберите значение",
    time_hh: "чч",
    time_mm: "мм",
  },
  login: {
    login_placeholder: "E-mail",
    password_placeholder: "Пароль",
    login: "Войти",
    error_text: "Неправильное имя пользователя или пароль",
  },
  monitoring: {
    city: "Город",
    pos: "Точка продаж",
    badge_status: "Статус бeйджа",
    device_type: "Тип устройства",
    apply_filters: "Применить",
    clear_filters: "Очистить фильтры",
    appearance: "Отображение",
    not_specified: "Не указано",
    headers: {
      city: "Город",
      pos_name: "Точка продаж",
      device_type: "Тип устройства",
      device_id: "ID устройства",
      device_status: "Статус устройства",
      badge_id: "ID бeйджа",
      uid: "ID польз.",
      last_name: "Имя",
      first_name: "Фамилия",
      auth_time: "Время авториз.",
      n_a_time: "Неучтённое время",
      active_time: "Время активности",
      total_time: "Сумм. время",
      badge_status: "Статус бeйджа",
      fw_ver: "Версия ПО",
    },
    btn: {
      apply: "Применить",
      select_all: "Выбрать все",
    },
  },
  hubs: {
    hub_id: "ID хаба",
    hub_id_hint: "Обязательно",
    pos: "Точка продаж",
    city: "Город",
    address: "Адрес офиса",
    add_btn: "Добавить",
    choose_city: "Выберите город из списка",
    choose_pos: "Выберите точку продаж из списка",
    empty_city: "Нет добавленных городов",
    empty_cities: "Необходимо добавить города присутствия в разделе",
    settings: "Настройки",
    filters: "Фильтры",
    loader: {
      progress: "Идёт добавление. Не покидайте вкладку до завершения",
      progress_edit: "Идет изменение. Не покидайте вкладку до завершения",
      failed: "Не удалось добавить хаб",
      failed_edit: "Не удалось изменить хаб",
      no_mac: "Введён некорректный MAC-адрес",
      success: "Устройство успешно добавлено",
      success_edit: "Устройство успешно изменено",
    },
    headers: {
      hub_id: "ID хаба",
      local_ip: "Локальный IP адрес",
      pos: "Точка продаж",
      city: "Город",
      address: "Адрес",
      badges: "Бeйджи",
      hub_status: "Статус хаба",
      timezone: "Часовой пояс",
      fw_ver: "Версия ПО",
    },
    confirm_text: "Вы собираетесь удалить хаб. Вы уверены?",
    confirm_button: "Удалить",
    confirm_add_text:
      "Все сотрудники на хабе перезапишутся сотрудниками из базы клауда",
    confirm_add_button: "Перезаписать",
    change_block: {
      recording_mode: "Режим записи",
      continuous_recording: "Постоянная запись",
      with_button_recording: "Запись по кнопке",
      upload_mode: "Режим загрузки",
      immediate_upload: "Сразу",
      postponed_upload: "Отложенная загрузка",
      motion_detection: "Обнаружение движения",
      on: "Вкл.",
      off: "Выкл.",
      battery_level: 'Уровень заряда батареи "Готов к использованию"',
      update_msg: "Параметр обновлен",
      update_msg_error: "Ошибка. Настройки не обновленны",
      hints: {
        upload: "Введите местное время выгрузки данных на сервер",
        motion:
          "Если функция включена, бейдж автоматически останавливает запись при отсутствии движения и автоматически возобновляет запись при появлении движения.",
      },
      save: "Сохранить",
      cancel: "Отмена",
      delete: "Удалить",
    },
  },
  applications: {
    comment: "Комментарий",
    username: "Имя пользователя",
    password: "Пароль",
    pos: "Точка продаж",
    city: "Город",
    address: "Адрес",
    email: "Email",
    recording_mode: "Режим записи",
    recording_start: "Начало записи",
    recording_end: "Конец записи",
    motion_detection: "Детекция движения",
    generate: "Сгенерировать",
    create: "Добавить",
    on: "Включено",
    off: "Выключено",
    on_timer: "По таймеру",
    on_button_press: "По кнопке",
    no_content: "Вы еще не настроили доступ к мобильному приложению",
    loader: {
      failed: "Не удалось создать приложение",
      failed_edit: "Не удалось изменить приложение",
      success: "Приложение успешно добавлено",
      progress: "Приложение создается. Пожалуйста, подождите",
      progress_edit: "Приложение сохраняется. Пожалуйста, подождите",
      success_edit: "Приложение успешно изменено",
    },
    headers: {
      comment: "Комментарии",
      app_id: "ID приложения",
      employee: "Имя",
      pos_name: "Точка продаж",
      city: "Город",
      pos_address: "Адрес",
      recording_mode: "Режим",
      recording_mode_text: "Режим записи",
      status: "Статус",
      badge_id: "ID бейджа",
      fw_version: "Версия ПО",
    },
    confirm_text:
      "Вы хотите отозвать доступ к мобильному приложению. Вы уверены?",
    confirm_button: "Отозвать",
    cancel_button: "Отмена",
    change_block: {
      save: "Сохранить",
      cancel: "Отмена",
      delete: "Удалить",
    },
    errors: {
      mail_exist: "Электронный адрес уже используется сотрудником: ",
      no_password: "Пароль не соответствует требованиям",
      no_mail: "Неверный формат электронной почты",
    },
  },
  team: {
    first_name: "Имя",
    last_name: "Фамилия",
    uid: "ID пользователя",
    text_field_hint: "Обязательно",
    generate: "Сгенерировать",
    add_user: "Добавить",
    all_cities: "Все города",
    all_poses: "Все точки продаж",
    not_selected_city: "Не выбран город",
    not_selected_pos: "Не выбрана точка продаж",
    empty_city: "Нет добавленных городов",
    clear_filters: "Очистить фильтры",
    empty_pos: "Нет добавленных точек продаж",
    city: "Город",
    pos: "Точка продаж",
    empty_cities: "Необходимо добавить города присутствия в разделе",
    settings: "Настройки",
    filters: "Фильтры",
    endings_city: {
      multiple_a: "города",
      multiple_b: "городов",
      single: "город",
    },
    endings_pos: {
      multiple_a: "точки продаж",
      multiple_b: "точек продаж",
      single: "точка продаж",
    },
    loader: {
      progress: "Аккаунт создается. Пожалуйста, подождите",
      progress_edit: "Идет изменение. Пожалуйста, подождите",
      failed: "Не удалось создать аккаунт",
      failed_edit: "Не удалось изменить аккаунт",
      employee_id_exist: "Пользователь с таким ID уже существует",
      success: "Аккаунт успешно добавлен",
      success_edit: "Аккаунт успешно изменен",
      no_auth:
        "Выбранный пользователь сейчай авторизован в системе и не может быть удален",
    },
    headers: {
      first_name: "Имя",
      last_name: "Фамилия",
      employee_id: "ID пользователя",
      authorized: "Авторизован",
    },
    change_block: {
      save: "Сохранить",
      cancel: "Отмена",
      delete: "Удалить",
    },
    confirm_text: "Вы собираетесь удалить пользователя. Вы уверены?",
    auth_error:
      "Пользователь авторизован системе и в данный момент не может быть удалён",
    close_button: "Закрыть",
    confirm_button: "Удалить",
  },
  accounts: {
    first_name: "Имя",
    last_name: "Фамилия",
    position: "Должность",
    mail: "E-mail",
    password: "Пароль",
    generate: "Сгенерировать",
    text_field_hint: "Обязательно",
    add_hubs: "Добавлять хабы",
    add_users: "Добавлять польз.",
    application_permission: "Доступ к приложениям",
    change_settings: "Настройка хаба",
    yes: "Да",
    no: "Нет",
    access_mode: "Зона ответственности",
    all: "Все",
    selected_only: "Только выбранные:",
    create: "Создать аккаунт",
    all_cities: "Все города",
    all_poses: "Все точки продаж",
    not_selected_city: "Не выбран город",
    not_selected_pos: "Не выбрана точка продаж",
    empty_city: "Нет добавленных городов",
    empty_pos: "Нет добавленных точек продаж",
    city: "Город",
    pos: "Точка продаж",
    empty_cities: "Необходимо добавить города присутствия в разделе",
    settings: "Настройки",
    endings_city: {
      multiple_a: "города",
      multiple_b: "городов",
      single: "город",
    },
    endings_pos: {
      multiple_a: "точки продаж",
      multiple_b: "точек продаж",
      single: "точка продаж",
    },
    headers: {
      account_id: "ID",
      first_name: "Имя",
      last_name: "Фамилия",
      position: "Должность",
      mail: "E-mail",
      date: "Дата создания",
    },
    confirm_text:
      "ВНИМАНИЕ! Вы собираетесь удалить аккаунт менеджера. Вы уверены?",
    confirm_button: "Удалить",
    change_block: {
      save: "Сохранить",
      cancel: "Отмена",
      delete: "Удалить",
    },
  },
  settings: {
    settings: "Настройки",
    password: {
      change: "Изменить пароль",
      new_password: "Новый пароль",
      confirm_password: "Подтвердите новый пароль",
      policy_title: "Требования к паролю",
      policy: [
        "Длина пароля не менее 8 символов",
        "Пароль должен содержать минимум один символ верхнего регистра",
        "Пароль должен содержать минимум один символ нижнего регистра",
        "Пароль должен содержать минимум один числовой символа",
        "Пароль должен содержать минимум один специальный символ",
      ],
      loader: {
        progress: "Изменяем пароль. Пожалуйста, подождите",
        failed: "Не удалось изменить пароль",
        success: "Пароль успешно изменен",
        confirm: "Введите пароль ещё раз",
        wrong_password: "Пароли отличаются",
        no_requirements: "Новый пароль не соответствует требованиям",
      },
      apply: "Применить",
    },
    hub: {
      title: "Настройки хаба",
      settings: {
        recording_mode: "Запись",
        continuous_recording: "Постоянная запись",
        with_button_recording: "Запись по кнопке",
        upload_mode: "Выгрузка",
        immediate_upload: "Сразу",
        postponed_upload: "Отложенная загрузка",
        motion_detection: "Обнаружение движения",
        on: "Вкл.",
        off: "Выкл.",
        battery_level: 'Уровень заряда батареи "Готов к использованию"',
        update_msg: "Параметр обновлен",
        update_msg_error: "Ошибка. Настройки не обновленны",
        hints: {
          upload:
            "При подключению к хабу, данные из бейджа могут выгружаться на сервер сразу, или в выбранный час по местному времени.",
          motion:
            "Если функция включена, бейдж автоматически останавливает запись при отсутствии движения и автоматически возобновляет запись при появлении движения.",
        },
      },
      loader: {
        progress: "Изменяем настройки. Пожалуйста, подождите",
        failed: "Не удалось изменить настройки",
        success: "Настройки успешно изменены",
      },
      apply: "Применить",
    },
    upload: {
      title: "Выгрузка данных",
      mode: "Режим",
      token: "Токен",
      key: "Ключ доступа ID",
      secret_key: "Секретный ключ доступа",
      api_key: "API ключ",
      username: "Имя пользователя",
      data_processing_enable: "Обработка аудио на сервере",
      dialog_frame_spotting: "Выделение диалогов по ключевым словам",
      upload_volume: "Объем выгрузки",
      upload_volume_options: {
        all: "Полная",
        optimal: "Частичная",
        minimal: "Минимальная",
      },
      bucket_name: "Bucket name",
      region: "Регион",
      host: "Хост",
      port: "Порт",
      user: "Логин",
      on: "Вкл.",
      off: "Выкл.",
      password: "Пароль",
      save: "Сохранить",
      hint: {
        data_processing_enable:
          "Включение обработки аудио на сервере (разделение аудиофайлов на отдельные фрагменты голосовой активности и/или по ключевым словам",
        dialog_frame_spotting:
          "Разделение аудиофайлов на диалоги на основании слов приветствия и слов прощания.",
        upload_volume:
          "<b>Полная</b> - все файлы отправляются в речевую аналитику." +
          "<br><b>Частичная</b> - только рабочие диалоги отправляются в речевую аналитику. Остальное загружается в хранилище." +
          "<br><b>Минимальная</b> - только рабочие диалоги добавляются в речевую аналитику и в хранилище. Остальное удаляется",
      },
      loader: {
        progress: "Изменяем настройки. Пожалуйста, подождите",
        failed: "Не удалось изменить настройки",
        success: "Настройки успешно изменены",
      },
      apply: "Применить",
    },
    accesses: {
      title: "Доступы для Speechmate",
      audio_access: "Предоставить доступ к аудиозаписям команде Спичмейт?",
      support: "Включить расширенную техническую поддержку?",
      warning:
        "Внимание!<br><br>" +
        " Предоставляя доступ вы получаете разервные копии аудио и поддерживаете развитие сервиса Speechmate",
      yes: "Да",
      no: "Нет",
      loader: {
        progress: "Изменяем настройки. Пожалуйста, подождите",
        failed: "Не удалось изменить настройки",
        success: "Настройки успешно изменены",
      },
    },
    api: {
      title: "Доступ к API",
      add_access: "Открыть доступ",
      public_key: "Публичный ключ",
      private_key: "Закрытый ключ",
      remove_access: "Закрыть доступ",
      generate: "Сгенерировать новый",
      no_content: "Открытых доступов нет",
      add_confirm: {
        text: "Доступ предоставлен. Сохраните ваш приватный ключ перед закрытием этого окна:",
        confirm: "Закрыть",
      },
      remove_confirm: {
        text: "Вы уверены, что хотите отозвать доступ?",
        confirm: "Отозвать",
        cancel: "Отмена",
      },
      headers: {
        public_key: "Публичный ключ",
        client_id: "ID",
      },
    },
    city: {
      title: "Добавление городов присутствия",
      city: "Город",
      create: "создан",
      delete: "удален",
      delete_count: "удаляете",
      count_end: "город",
      warning: "Вы",
      create_count: "добавляете",
      not: "не был",
      is: "был",
      and: "и",
      apply: "Применить",
      text_field_hint: "Все города",
      check: {
        general: "Внимание! Для удаления города удалите привязанные к нему",
        hubs: `хабы`,
        applications: `приложения`,
        employees: `сотрудников`,
        accounts: `аккаунты`,
        poses: `точки продаж`,
        ending: "или внесите соотвествующие изменения.",
      },
      endings: {
        multiple_a: "города",
        multiple_b: "городов",
        single: "город",
      },
    },
    pos: {
      title: "Добавление точек продаж",
      pos: "Точка продаж",
      city: "Город",
      add: "Добавить точку",
      apply: "Применить",
      new_pos: "Введите название",
      delete_count: "удаляете",
      create_count: "добавляете",
      warning: "Вы",
      not: "не был",
      is: "был",
      and: "и",
      check: {
        general:
          "Внимание! Для удаления точки продаж удалите привязанные к нему",
        hubs: `хабы`,
        applications: `приложения`,
        employees: `сотрудников`,
        accounts: `аккаунты`,
        poses: `точки продаж`,
        ending: "или внесите соотвествующие изменения.",
      },
      endings: {
        multiple_a: "точки продаж",
        multiple_b: "точек продаж",
        single: "точку продаж",
      },
      headers: {
        name: "Наименование точки продаж",
        city_name: "Город",
      },
    },
  },
  monitoring_owner: {
    company: "Компания",
    city: "Город",
    pos: "Точка продаж",
    apply_filters: "Применить",
    clear_filters: "Очистить фильтры",
    appearance: "Отображение",
    headers: {
      comment: "Комментарий",
      customer_id: "ID клиента",
      legal_name: "Юр. лицо",
      company: "Компания",
      city: "Город",
      hub_id: "ID хаба",
      device_type: "Тип устройства",
      pos: "Точка продаж",
      address: "Адрес",
      timezone: "Часовой пояс",
      errors: "Ошибки",
      last_sync: "Посл. загрузка",
      device_fw_ver: "Версия ПО хаба",
      status: "Статус",
    },
  },
  status: {
    log: "Журнал регистрации событий",
    log_download: "Скачать журнал",
    info: {
      customer: "Клиент",
      pos: "Точка продаж",
      address: "Адрес",
      comment: "Комментарий",
      timezone: "Часовой пояс",
      hub_id: "ID хаба",
      battery_level: "Заряд батареи, достаточный для работы",
      upload_mode: "Выгрузка данных",
      recording_mode: "Режим записи",
      motion: "Определение движения",
      fw_ver: "Версия ПО хаба",
      yes: "Да",
      no: "Нет",
      postponed: "В ",
      immediate: "Сразу",
      continuous_recording: "Постоянная запись",
      with_button_recording: "Запись по кнопке",
      online: "Онлайн",
      offline: "Офлайн",
    },
    headers: {
      badge_id: "ID бeйджа",
      uid: "ID польз.",
      auth_time: "Время авториз.",
      n_a_time: "Неучтённое время",
      active_time: "Время активности",
      total_time: "Сумм. время",
      badge_status: "Статус бeйджа",
      fw_ver: "Версия ПО",
      date: "Дата",
      description: "Описание",
    },
  },
  customers: {
    customer_id: "ID клиента",
    password: "Пароль",
    legal_name: "Юр. лицо",
    company: "Компания",
    admin_name: "Имя администратора",
    mail: "E-mail",
    phone: "Телефон",
    add_customer: "Добавить клиента",
    text_field_hint: "Обязательно",
    loader: {
      progress: "Добавление в процессе. Не закрывайте вкладку",
      progress_edit: "Редактирование в процессе. Не закрывайте вкладку",
      failed: "Не удалось создать клиента",
      failed_edit: "Не удалось изменить клиента",
      id_exist: "Клиент с таким ID уже существует",
      mail_exist: "Клиент с таким e-mail уже существует",
      success: "Клиент успешно добавлен",
      success_edit: "Клиент успешно изменен",
    },
    errors: {
      no_phone: "Пожалуйста, проверьте корректность номера телефона.",
      no_password: "Пароль не соответствует требованиям",
      no_mail: "Неверный формат электронной почты",
      value: "Ошибка в данных. Пожалуйста, проверьте заполненную информацию",
    },
    headers: {
      customer_id: "ID клиента",
      legal_name: "Юр. лицо",
      company: "Компания",
      admin_name: "Имя администратора",
      mail: "E-mail",
      phone: "Телефон",
    },
    generate: "Сгенерировать",
    confirm_text:
      "ВНИМАНИЕ! Вы собираетесь удалить аккаунт клиента. Вы уверены?",
    confirm_button: "Удалить",
    change_block: {
      save: "Сохранить",
      cancel: "Отмена",
      delete: "Удалить",
    },
  },
  components: {
    battery_level: {
      title: 'Уровень заряда батареи "Готов к использованию"',
      hint: "Введите минимальный процент заряда батареи, позволяющий выдачу этого бeйджа",
    },
    table_btn: {
      edit: "Редактировать",
      delete: "Удалить",
    },
  },
  errors: {
    required: "Заполните обязательное поле",
    invalid_range: "Указан неверный диапазон",
    format: "Неверный формат ввода",
    no_letters: "Должна быть хотя бы одна буква",
    numbers_and_letters_only: "Для заполнения допускаются только буквы и цифры",
    numbers_letters_symbols:
      "Для заполнения допускаются только буквы, цифры и знаки №#.,/-_:;",
    no_password: "Пароль не соответствует требованиям",
    value: "Ошибка в данных. Пожалуйста, проверьте заполненную информацию",
  },
  log: {
    copy_error: "Ошибка копирования файла",
    decrypt_error: "Ошибка расшифровки файла",
    upload_error: "Ошибка загрузки файла",
    usb_error: "Ошибка в распознавании USB устройства",
    block_error: "Ошибка раздела бейджа",
    hub_added: "Хаб добавлен",
    hub_deleted: "Хаб удален",
    hub_offline: "Хаб офлайн",
    hub_online: "Хаб онлайн",
    hub_settings_cloud: "Обновлены настройки хаба с клауда",
    hub_settings_hub: "Обновлены настройки хаба с хаба",
    unknown_error: "Неизвестная ошибка",
    meta_upload_error: "Ошибка выгрузки метаданных",
  },
  badge: {
    status: {
      recording: "Запись",
      on_body: "На теле",
      waiting: "Ожидание",
      rebooted: "Перезагружен",
      syncing: "Синхронизация",
      sync_complete: "Синхронизация завершена",
      sync_error: "Ошибка",
      charging: "Зарядка",
      not_in_range: "Вне зоны",
      connecting: "Подключение",
      idle: "Бейдж не подключен к хабу",
      upload: "Загрузка",
      upload_error: "Ошибка выгрузки",
      error: "Ошибка",
      offline: "Хаб офлайн",
      application_offline: "Приложение офлайн",
    },
  },
  device: {
    type: {
      hub: "Хаб",
      app: "Приложение",
    },
    status: {
      online: "Онлайн",
      offline: "Не доступен",
    },
  },
};
